import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

const IndexPage = () => {
	const [ amount, setAmount ] = useState(1);

	const paypal = window.paypal || null;

	useEffect(() => {
    if (paypal) {
			const style = {
				shape: 'rect',
				color: 'gold',
				layout: 'vertical',
				label: 'paypal',
			};

			const createOrder = (amount) => {
				return (data, actions) => {
					return actions.order.create({
							purchase_units: [{
									amount: {
											value: 1
									}
							}]
					});
				}
			};

			const onApprove = (data, actions) => {
				return actions.order.capture().then(function(details) {
					Modal.info({
						title: 'Bedankt',
						content: (
							<div>
								<p>Bedankt, voor je donatie. {details.payer.name.given_name}</p>
							</div>
						),
						onOk() {},
					});
				});
			};

      paypal.Buttons({
				style,
				createOrder: createOrder(1),
				onApprove
			}).render('#paypal-button-1');

      paypal.Buttons({
				style,
				createOrder: createOrder(5),
				onApprove
			}).render('#paypal-button-5');

			paypal.Buttons({
				style,
				createOrder: createOrder(10),
				onApprove
			}).render('#paypal-button-10');
    }
	}, []);



     
	return (
		<div className="container">
			<h1>Doneer en help ons</h1>
			<p>Je kunt eenvoudig een donatie doen via Paypal. Selecteer hieronder het gewenste bedrag.</p>
			{/* <a onClick={() => setAmount(1)} className="button">Doneer 1 euro</a>
			<a onClick={() => setAmount(5)} className="button">Doneer 5 euro</a>
			<a onClick={() => setAmount(10)} className="button">Doneer 10 euro</a> */}
			<h1>Doneer 1 euro</h1>
			<div id="paypal-button-1"></div>
			<h1>Doneer 5 euro</h1>
			<div id="paypal-button-5"></div>
			<h1>Doneer 10 euro</h1>
			<div id="paypal-button-10"></div>	
		</div>
	);
};

export default IndexPage;
