import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { EventContext } from '../../contexts/eventContext';
import defaultImage from '../../images/image_default.jpg';
import Loader from '../loader';

const EventPage = () => {
	const { eventItems, eventItemsError, eventItemsLoading, getEventItems } = useContext(EventContext);
	useEffect(() => {
		getEventItems(false, 1, 10, { type: 'event' });
	}, []);

	return (
		<div className="container clear">
			<section className="content">
				<h1>Evenementen</h1>

				{ !eventItemsError && eventItemsLoading &&
					<div style={{marginTop: 100, marginBottom: 100 }}>
						<Loader />
					</div>
				}	

				{!eventItemsError &&
				!eventItemsLoading &&
				eventItems && (
					<ul className="news_list">
						{eventItems.map((eventItem, index) => (
							<li key={`eventitem${index}`}>
								<Link to={`/evenementen/${eventItem._id}`}>
									<img alt={eventItem.title} src={eventItem.image ? eventItem.image.replace('public/images', 'public/images_resized') :  defaultImage} />
									<div>
										<h2>
											{`
											${new Date(eventItem.dateTime).getDate()}-${new Date(eventItem.dateTime).getMonth() + 1}-${new Date(
												eventItem.dateTime
											).getFullYear()}
											`}
										</h2>
										<p>
											{eventItem.intro}
											<span className="blue-text">
												<br />
												Lees meer...
											</span>
										</p>
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</section>
		</div>
	);
};

export default EventPage;
