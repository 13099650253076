import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { NewsContext } from '../../contexts/newsContext';
import { SponsorContext } from '../../contexts/sponsorContext';
import { ResponseContext } from '../../contexts/responseContext';
import { StationContext } from '../../contexts/stationContext';

import defaultImage from '../../images/image_default.jpg';
import bgImage from '../../images/image_bg.jpg';
import vitaminenImage from '../../images/icon_vitamine.png';
import paypalImage from '../../images/paypal.png';
import tipImage from '../../images/tip.png';
import teamImage from '../../images/team.jpg';

import AddResponseForm from '../addResponseForm';
import { Modal } from 'antd';
import Loader from '../loader';

const IndexPage = () => {
	const {
		getFocusNewsItems,
		focusNewsItemsError,
		focusNewsItemsLoading,
		focusNewsItems,
		getFocusEventItem,
		focusEventItem,
		focusEventItemError,
		focusEventItemLoading,
	} = useContext(NewsContext);
	
	const {
		smallSponsors, mediumSponsors, largeSponsors,
		sponsors, sponsorsError, sponsorsLoading, getSponsors
	} = useContext(SponsorContext);

	const {
    onlineStation,
    onlineStationError,
    onlineStationLoading,
  } = useContext(StationContext)

	//const { responses, responsesError, responsesLoading, getResponses } = useContext(ResponseContext);

	const [ showModal, setShowModal ] = useState(false);
	const {
		responses, responsesError, responsesLoading, getResponses,
		createResponse: createItem, responseCreating, responseCreatingError
	} = useContext(ResponseContext);

	useEffect(() => {
    getFocusEventItem(null, true);
	}, []);

	useEffect(() => {
		getFocusNewsItems();
	}, []);

	useEffect(() => {
    getSponsors();
	}, []);

	useEffect(() => {
    getResponses();
	}, []);

	const createResponse = async (responseToCreate) => {
		const created = await createItem(responseToCreate);
		if (created) {
			Modal.info({
				title: 'Reactie ontvangen',
				content: (
					<div>
						<p>Bedankt, we hebben je reactie ontvangen.</p>
						<p>Voordat deze zichtbaar is zullen onze DJ's deze beoordelen</p>
					</div>
				),
				onOk() {},
			});
      getResponses(true);
		} else {
			alert('Kon reactie niet plaatsen. Probeer het nog eens.');
		}
	};
	
	if (responseCreating) {
		return (
			<div style={{marginTop: 100, marginBottom: 100 }}>
				<Loader />
			</div>
		);
	}

	return (
		<Fragment>
			<div className="video_comments">
				<div className="container container-flex">
					<section className="video_wrapper">

						{!onlineStationError && !onlineStationLoading && onlineStation && (
							<span className="onlineStation" to={`/stations/${onlineStation._id}`}>
								<section className="news_block" style={{ marginBottom: 24 }}>
									<Link className="onlineStation" to={`/stations/${onlineStation._id}`}>
										<img
												alt={onlineStation.name}
												src={onlineStation.logo ? onlineStation.logo.replace('public/images', 'public/images_resized') : defaultImage}
											/>
									</Link>
									<div>
										<h2>
											{onlineStation.name}
										</h2>
										<p style={{color: '#000', textDecoration: 'none'}}>{onlineStation.slogan}</p>
										<div>
											<span style={{float: 'left'}}>{onlineStation.phoneNumber}</span>
											<span style={{float: 'right'}}>
												<a onClick={() => setShowModal(true)}><span>Plaats reactie</span></a>
											</span>
											<br style={{ clear: 'both'}}/>
										</div>
										{/* <p>{onlineStation.shortDescription}</p> */}
									</div>
								</section>
							</span>
						)}

						{showModal && (
							<Modal
								title="Reactie plaatsen"
								visible={true}
								onCancel={() => setShowModal(false)}
								footer={null}
								width={'90vw'}
								style={{
									maxWidth: 500
								}}
							>
								<AddResponseForm
									onCreate={createResponse}
									onClose={() => setShowModal(false)}
								/>
							</Modal>
						)}

						<div className="block_link">
							<Link to="/programma" />
							<div className="block_link_img block_link_vitaminen">
								<h1>
									Arbeidsvitamines <img src={vitaminenImage} width="30" alt="Arbeidsvitamines" />
								</h1>
								<h2>Bekijk ons programma</h2>
							</div>
						</div>
					</section>

					<section className="comments">
						{ !responsesError && responsesLoading &&
							<div style={{marginTop: 100, marginBottom: 100 }}>
								<Loader />
							</div>
						}	

						{ !responsesError &&
						!responsesLoading &&
						responses && (
							<Fragment>
								<ul>
									{ responses.slice(0, 3).map(response => (
										<li key={response._id}>
											<span>{response._id.substr(0, 4)}</span>
											<h3>
												<span>{response.name}</span> op 
												<span>
													{`
													${new Date(response.dateTime).getDate()}-${new Date(response.dateTime).getMonth()+1}-${new Date(response.dateTime).getFullYear()}
													`}
												</span>
											</h3>
											<p>{response.text}</p>
										</li>
									))}
								</ul>
								<Link to="/reacties"><span>Alle reacties</span></Link>
							</Fragment>
						)}
						
					</section>
				</div>
			</div>

			<div className="content_dark">
				<div className="container">
					<div className="column_left">
						<section className="sponsor">
							<h1>Sponsoren</h1>
							{ !sponsorsError &&
								!sponsorsLoading &&
								sponsors && (
									<ul>
										{smallSponsors.slice(0, 2).map((sponsor) => (
											<li key={sponsor._id} className="small">
												<a target="_blank" href={sponsor.url}>
													<img
														src={sponsor.image.replace('public/images', 'public/images_resized')}
														alt={sponsor.name}
													/>
												</a>
											</li>
										))}
										<li className="small advert">
											<a target="_blank" href="mailto:info@hitstreamfm.nl">Uw logo hier?</a>
										</li>
									</ul>
							)}
						</section>

						{focusEventItem &&
						!focusEventItemError &&
						!focusEventItemLoading && (
							<section className="info_block">
								<Link to={`/evenementen/${focusEventItem._id}`}>
									<div>
										<img className="info_block_bg" src={focusEventItem.image ? focusEventItem.image.replace('public/images', 'public/images_resized') : bgImage} />
										<div>
											<span>Uitgelicht evenement</span>
											<h1>
												{focusEventItem.title.length > 14 ? (
													`${focusEventItem.title.substr(0, 12)}..`
												) : (
													focusEventItem.title
												)}
											</h1>
											<p className="white">{focusEventItem.intro}</p>
										</div>
									</div>
								</Link>
							</section>
						)}

						<Link className="paypal_link" to={`/donate`}>
							<section className="paypal">
								<h2>
									Steun ons en doneer via <img src={paypalImage} alt="Steun ons en doneer via PayPal" />
								</h2>
							</section>
						</Link>


						{!focusNewsItemsError &&
						!focusNewsItemsLoading &&
						focusNewsItems && (
							<Fragment>
								{focusNewsItems.map((newsItem) => (
									<Link key={newsItem._id} to={`/nieuws/${newsItem._id}`}>
										<section className="news_block">
											<img alt={newsItem.title} src={newsItem.image ? newsItem.image.replace('public/images', 'public/images_resized') :  defaultImage} />
											<div>
												<h2>
													{`
													${new Date(newsItem.dateTime).getDate()}-${new Date(newsItem.dateTime).getMonth() + 1}-${new Date(
														newsItem.dateTime
													).getFullYear()}
													`}
													{/* {newsItem.title} */}
												</h2>
												<p>{newsItem.intro}</p>
											</div>
										</section>
									</Link>
								))}
							</Fragment>
						)}
					</div>
					<div className="column_right">
						<section className="links">
							<div>
								<h3>Da’s handig</h3>
								<ul>
									<li>
										<Link to="/stations">Vind alle aangesloten radiostations</Link>
									</li>
									<li>
										<Link to="/luistertips">Bekijk onze luistertips</Link>
									</li>
									<li>
										<a href="mailto:info@hitstream.nl">
											<strong>Adverteren op hitstream.fm</strong> <img src={tipImage} />
										</a>
									</li>
								</ul>
							</div>
							<img src={teamImage} alt="Hitstream.fm team" />
						</section>

						<div className="video">
							<video autoPlay={true} loop={true} muted="muted">
								<source src={'video_compressed_1.mp4'} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>

						<section className="sponsor">
							<h1>Sponsoren</h1>

							{ !sponsorsError &&
								!sponsorsLoading &&
								sponsors && (
									<Fragment>
									<ul>
										{mediumSponsors.slice(0, 2).map((sponsor) => (
											<li key={sponsor._id} className="medium">
												<a target="_blank" href={sponsor.url}>
													<img
														src={sponsor.image.replace('public/images', 'public/images_resized')}
														alt={sponsor.name}
													/>
												</a>
											</li>
										))}
									</ul><ul>
										{largeSponsors.slice(0, 2).map((sponsor) => (
											<li key={sponsor._id} className="medium">
												<a target="_blank" href={sponsor.url}>
													<img
														src={sponsor.image.replace('public/images', 'public/images_resized')}
														alt={sponsor.name}
													/>
												</a>
											</li>
										))}
									</ul><ul>
										{smallSponsors.slice(0, 2).map((sponsor) => (
											<li key={sponsor._id} className="small">
												<a target="_blank" href={sponsor.url}>
													<img
														src={sponsor.image.replace('public/images', 'public/images_resized')}
														alt={sponsor.name}
													/>
												</a>
											</li>
										))}
										<li className="small advert">
											<a target="_blank" href="mailto:info@hitstreamfm.nl">Uw logo hier?</a>
										</li>
									</ul>
								</Fragment>
							)}
						</section>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default IndexPage;
