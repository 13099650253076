import React, { Fragment, useState, useContext, useEffect } from 'react';
import { ResponseContext } from '../../contexts/responseContext';
import { SponsorContext } from '../../contexts/sponsorContext';
import AddResponseForm from '../addResponseForm';
import { Modal } from 'antd';
import Loader from '../loader';

const ResponsesPage = () => {
	const [ showModal, setShowModal ] = useState(false);
	const {
		responses, responsesError, responsesLoading, getResponses,
		createResponse: createItem, responseCreating, responseCreatingError
	} = useContext(ResponseContext);

	const {
		smallSponsors, mediumSponsors, largeSponsors,
		sponsors, sponsorsError, sponsorsLoading, getSponsors
	} = useContext(SponsorContext);

	useEffect(() => {
    getSponsors();
	}, []);

	useEffect(() => {
		getResponses(true);
	}, []);

	if (responsesLoading || responseCreating) {
		return (
			<div style={{marginTop: 100, marginBottom: 100 }}>
				<Loader />
			</div>
		);
	}

	if (responsesError) {
		return <h1>Responses error</h1>
	}

	const createResponse = async (responseToCreate) => {
		const created = await createItem(responseToCreate);
		if (created) {
			Modal.info({
				title: 'Reactie ontvangen',
				content: (
					<div>
						<p>Bedankt, we hebben je reactie ontvangen.</p>
						<p>Voordat deze zichtbaar is zullen onze DJ's deze beoordelen</p>
					</div>
				),
				onOk() {},
			});

      getResponses(true);
		} else {
			alert('Kon reactie niet plaatsen. Probeer het nog eens.');
		}
	};

	return (
		<div className="container clear">
			<section className="content_left">
				<div className="clear">
					<h1>Reacties</h1>
					<a onClick={() => setShowModal(true)}>
						<span>
							Plaats reactie
						</span>
					</a>
				</div>

				{showModal && (
					<Modal
						title="Reactie plaatsen"
						visible={true}
						onCancel={() => setShowModal(false)}
						footer={null}
						width={'90vw'}
						style={{
							maxWidth: 500
						}}
					>
						<AddResponseForm
							onCreate={createResponse}
							onClose={() => setShowModal(false)}
						/>
					</Modal>
				)}

				<section className="comments">
					{ responses && (
						<Fragment>
							{ responses.length === 0 &&
								<p style={{ fontSize: 16, marginTop: 16, textAlign: 'center' }}>Nog geen reacties</p>
							}
							<ul>
							{ responses.map((response, index) => (
								<li key={response._id}>
									<span>{index+1}</span>
									<h3>
										<span>{response.name}{` `}</span>
										<span>
											{`
											${new Date(response.dateTime).getDate()}-${new Date(response.dateTime).getMonth()+1}-${new Date(response.dateTime).getFullYear()}
											`}
										</span>
									</h3>
									<p>
										{response.text}
									</p>
								</li>
							))}
							</ul>
						</Fragment>
					)}
				</section>
			</section>
			<section className="sidebar">
				<section className="sponsor">
					<h1>Sponsoren</h1>
					{ !sponsorsError &&
						!sponsorsLoading &&
						sponsors && (
							<Fragment>
							<ul>
								{mediumSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="medium">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
							</ul><ul>
								{largeSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="medium">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
							</ul><ul>
								{smallSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="small">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
								<li className="small advert">
									<a target="_blank" href="mailto:info@hitstreamfm.nl">Uw logo hier?</a>
								</li>
							</ul>
						</Fragment>
					)}
				</section>
			</section>
		</div>
	);
};
export default ResponsesPage;
