import React, { useContext, useEffect } from "react"

import { Router } from "@reach/router"

import { StationContext } from "../contexts/stationContext"

import MainLayout from "../layouts/main"
import SEO from "../components/seo"

import Index from "../components/pages/index"
import StationsPage from "../components/pages/stations"
import StationPage from "../components/pages/station"
import NewsPage from "../components/pages/news"
import NewsItemPage from "../components/pages/newsItem"
import EventPage from "../components/pages/events"
import EventItemPage from "../components/pages/eventItem"
import ResponsePage from "../components/pages/responses"
import FirePage from "../components/pages/fire"
import ProgramPage from "../components/pages/program"
import SuggestionsPage from "../components/pages/suggestions"
import DonatePage from "../components/pages/donate"

import WhatsappImage from "../images/whatsapp.svg"

const IndexPage = () => {
  const {
    onlineStation,
    onlineStationError,
    onlineStationLoading,
    getOnlineStation,
  } = useContext(StationContext)
  useEffect(() => {
    getOnlineStation()
  }, [])

  const url = typeof window !== 'undefined' ? window.location.href : '';
  const firePage = url.indexOf('openhaard') > -1;

  return (
    <MainLayout
      onlineStation={onlineStation}
      onlineStationError={onlineStationError}
      onlineStationLoading={onlineStationLoading}
      getOnlineStation={getOnlineStation}
    >
      <SEO title="Home" />

      { !firePage &&
        <section className="listen">
          <div className="container">
            <h1>Luisterlinks</h1>
            <ul>
              <li className="realplayer">
                <a href="/static/hitstream.ram">
                  <span>realplayer</span>
                </a>
              </li>
              <li className="winamp">
                <a href="/static/hitstream.pls">
                  <span>winamp</span>
                </a>
              </li>
              <li className="quicktime">
                <a href="/static/hitstream.qtl">
                  <span>quicktime</span>
                </a>
              </li>
              <li className="mediaplayer">
                <a href="/static/hitstream.asx">
                  <span>mediaplayer</span>
                </a>
              </li>
              <li className="website">
                <a href="/static/hitstream.asx">
                  <span>website</span>
                </a>
              </li>
              <li className="mobile">
                <a href="/static/hitstream_mobile.pls">
                  <span>mobile</span>
                </a>
              </li>
            </ul>
            {!onlineStationError && !onlineStationLoading && onlineStation && (
              <p>
                U luistert naar de hits van <span>{onlineStation.name}</span>
                {onlineStation.whatsappNumber && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp"
                    href={`https://wa.me/${onlineStation.whatsappNumber}`}
                  >
                    <img alt="whatsapp" src={WhatsappImage} />
                  </a>
                )}
              </p>
            )}
          </div>
        </section>
      }

      <Router basepath="/">
        <StationPage path="/stations/:stationid" />
        <StationsPage path="/stations" />
        <NewsItemPage path="/nieuws/:newsid" />
        <NewsPage path="/nieuws" />
        <EventItemPage path="/evenementen/:eventid" />
        <EventPage path="/evenementen" />
        <ProgramPage path="/programma" />
        <ResponsePage path="/reacties" />
        <FirePage path="/openhaard" />
        <SuggestionsPage path="/luistertips" />
        <DonatePage path="/donate" />
        <Index path="/" />
      </Router>
    </MainLayout>
  )
}

export default IndexPage
