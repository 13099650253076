import React from 'react';
import { Form, Button, Input } from 'antd';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 }
	}
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 12,
			offset: 5
		}
	}
};

const AddResponseForm = ({ onCreate, onClose }) => {
	
	const onSubmit = (values) => {
		onCreate(values);
		onClose();
	};

	return (
		<Form
			{...formItemLayout}
			initialValues={{
				name: '',
				email: '',
				text: ''
			}}
			onFinish={onSubmit}
		>
			<Form.Item label="Name" name="name" rules={[ { required: true, message: 'Naam is verplicht' } ]}>
				<Input maxLength="20" />
			</Form.Item>

			<Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Geen geldig email adres'}, { required: true, message: 'Email is verplicht' } ]}>
				<Input />
			</Form.Item>

			<Form.Item label="Bericht" name="text" rules={[ { required: true, message: 'Bericht is verplicht' } ]}>
				<Input.TextArea maxLength="250" rows={6} />
			</Form.Item>

			<Form.Item {...tailLayout}>
				<Button
					htmlType="submit"
					style={{
						color: '#fff',
						fontSize: 16,
						backgroundColor: '#39cbaa',
						padding: '15px 30px',
						borderRadius: 4,
						height: 'auto'
					}}
				>
					Reactie plaatsen
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AddResponseForm;
