import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { StationContext } from '../../contexts/stationContext';
import defaultImage from '../../images/image_default.jpg';
import Loader from '../loader';

const StationsPage = () => {
	const { stations, stationsError, stationsLoading, getStations } = useContext(StationContext);
	
  useEffect(() => {
		getStations();
	}, []);

	return (
		<div className="container clear">
			<section className="content">
				<h1>Stations</h1>

				{ !stationsError && stationsLoading &&
					<div style={{marginTop: 100, marginBottom: 100 }}>
						<Loader />
					</div>
				}	

				{!stationsError &&
				!stationsLoading &&
				stations && (
					<ul className="station_list">
						{ stations.map(station => (
							<li key={station._id}>
								<Link to={`/stations/${station._id}`}>
									<img
										alt={station.name}
										src={station.logo ? station.logo.replace('public/images', 'public/images_resized') : defaultImage}
									/>
									<div>
										<h2>
											{station.name}
										</h2>
										<p>
											{station.shortDescription}
											<a className="blue-text">
												<br/>
												Lees meer...
											</a>
										</p>
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</section>
		</div>
	);
};

export default StationsPage;
