import React, { useContext, useEffect, Fragment } from 'react';
import { StationContext } from '../../contexts/stationContext';
import { SponsorContext } from '../../contexts/sponsorContext';
import Loader from '../loader';
import { Carousel } from 'antd';
import '../../style/station.scss';

const StationPage = ({ stationid }) => {
	const { station, stationError, stationLoading, getStation } = useContext(StationContext);

	const {
		smallSponsors, mediumSponsors, largeSponsors,
		sponsors, sponsorsError, sponsorsLoading, getSponsors
	} = useContext(SponsorContext);

	useEffect(() => {
    getSponsors();
	}, []);

	useEffect(() => {
		getStation(stationid);
	}, [stationid]);

	if (stationLoading || (!stationLoading && !stationError && !station)) {
		return (
			<div style={{marginTop: 100, marginBottom: 100 }}>
				<Loader />
			</div>
		);
	}

	if (stationError) {
		return <h1>StationError</h1>
	}

	return (
		<div className="container clear">
			<section className="content_left">
				<div className="clear">
					<h1>{station.name}</h1>
				</div>
				<div className="single_content">
					{/* {station.images && (
						<Carousel autoplay>
							{station.images.map((image, index) => (
								<div key={`photo${index}`}>
									<img src={image.replace('public/images', 'public/images_resized')} />
								</div>
							))}
						</Carousel>
					)} */}
					{/* <div dangerouslySetInnerHTML={{__html: station.description}}/> */}
					<div dangerouslySetInnerHTML={{ __html: station.description.replace(/public\/images/g, 'public/images_resized') }} />
				</div>
			</section>
			<section className="sidebar">
				<section className="sponsor">
					<h1>Sponsoren</h1>
					{ !sponsorsError &&
						!sponsorsLoading &&
						sponsors && (
							<Fragment>
							<ul>
								{mediumSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="medium">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
							</ul><ul>
								{largeSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="medium">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
							</ul><ul>
								{smallSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="small">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
								<li className="small advert">
									<a target="_blank" href="mailto:info@hitstreamfm.nl">Uw logo hier?</a>
								</li>
							</ul>
						</Fragment>
					)}
				</section>
			</section>
		</div>
	);
};
export default StationPage;
