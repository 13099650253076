import React from 'react';

const FirePage = () => {
	return (
		<div className="video">
			<img
				className="fire"
				src="images/fire.gif"
			/>
		</div>
	);
};

export default FirePage;
