import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { NewsContext } from '../../contexts/newsContext';
import { SponsorContext } from '../../contexts/sponsorContext';
import Loader from '../loader';
import arrow_back from '../../images/arrow_back.png';
import defaultImage from '../../images/image_default.jpg';

const NewsItemPage = ({ newsid }) => {
	const { newsItem, newsItemError, newsItemLoading, getNewsItem } = useContext(NewsContext);

	const {
		smallSponsors, mediumSponsors, largeSponsors,
		sponsors, sponsorsError, sponsorsLoading, getSponsors
	} = useContext(SponsorContext);

	useEffect(() => {
    getSponsors();
	}, []);

	useEffect(() => {
		getNewsItem(newsid);
	}, [newsid]);

	if (newsItemLoading || (!newsItemLoading && !newsItemError && !newsItem)) {
		return (
			<div style={{marginTop: 100, marginBottom: 100 }}>
				<Loader />
			</div>
		)
	}

	if (newsItemError) {
		return <h1>Er ging iets fout bij het laden..</h1>;
	}

	return (
		<div className="container clear">
			<section className="content_left">
				<div className="clear">
					<h1>{newsItem.title}</h1>
					<Link to="/nieuws">
						<span>
							<img src={arrow_back} />
							Naar overzicht
						</span>
					</Link>
				</div>
				<div className="single_content">
					<img alt={newsItem.title} src={newsItem.image ? newsItem.image.replace('public/images', 'public/images_resized') :  defaultImage} />
					<div>
						<h2>
							{`
							${new Date(newsItem.dateTime).getDate()}-${new Date(newsItem.dateTime).getMonth() + 1}-${new Date(
								newsItem.dateTime
							).getFullYear()}
							`}
						</h2>
						<div dangerouslySetInnerHTML={{ __html: newsItem.text.replace(/public\/images/g, 'public/images_resized') }} />
						{/* <div dangerouslySetInnerHTML={{ __html: newsItem.text }} /> */}
					</div>
				</div>
			</section>
			<section className="sidebar">
				<section className="sponsor">
					<h1>Sponsoren</h1>
					{ !sponsorsError &&
						!sponsorsLoading &&
						sponsors && (
							<Fragment>
							<ul>
								{mediumSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="medium">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
							</ul><ul>
								{largeSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="medium">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
							</ul><ul>
								{smallSponsors.slice(0, 2).map((sponsor) => (
									<li key={sponsor._id} className="small">
										<a target="_blank" href={sponsor.url}>
											<img src={sponsor.image} alt={sponsor.name} />
										</a>
									</li>
								))}
								<li className="small advert">
									<a target="_blank" href="mailto:info@hitstreamfm.nl">Uw logo hier?</a>
								</li>
							</ul>
						</Fragment>
					)}
				</section>
			</section>
		</div>
	);
};
export default NewsItemPage;
