import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { NewsContext } from '../../contexts/newsContext';
import defaultImage from '../../images/image_default.jpg';
import Loader from '../loader';

const NewsPage = () => {
	const { newsItems, newsItemsError, newsItemsLoading, getNewsItems } = useContext(NewsContext);
	useEffect(() => {
		getNewsItems(false, 1, 10, { type: 'news' });
	}, []);

	return (
		<div className="container clear">
			<section className="content">
				<h1>Nieuws</h1>

				{ !newsItemsError && newsItemsLoading &&
					<div style={{marginTop: 100, marginBottom: 100 }}>
						<Loader />
					</div>
				}	

				{!newsItemsError &&
				!newsItemsLoading &&
				newsItems && (
					<ul className="news_list">
						{newsItems.map((newsItem, index) => (
							<li key={`newsitem${index}`}>
								<Link to={`/nieuws/${newsItem._id}`}>
									<img alt={newsItem.title} src={newsItem.image ? newsItem.image.replace('public/images', 'public/images_resized') :  defaultImage} />
									<div>
										<h2>
											{`
											${new Date(newsItem.dateTime).getDate()}-${new Date(newsItem.dateTime).getMonth() + 1}-${new Date(
												newsItem.dateTime
											).getFullYear()}
											`}
										</h2>
										<p>
											{newsItem.intro}
											<span className="blue-text">
												<br />
												Lees meer...
											</span>
										</p>
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</section>
		</div>
	);
};

export default NewsPage;
